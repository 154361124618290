function RangeSlider() {
    var e = document.getElementsByClassName("r-slider");
    Array.from(e).forEach((function(e, t) {
        var n, i = document.createElement("div"),
            r = document.createElement("div"),
            d = document.createElement("div"),
            l = document.createElement("div"),
            s = document.createElement("div"),
            a = new CustomEvent("r-slider-updated", {
                detail: {
                    value: null
                }
            });
        e.insertAdjacentElement("afterbegin", i), i.classList.add("r-slider__circle"), i.insertAdjacentElement("afterbegin", r), r.classList.add("r-slider__cloud", "r-slider__cloud_moving", "r-slider_hidden");
        var u, c = function() {
                return i.getBoundingClientRect().width
            },
            o = function() {
                return parseFloat((e = i, "border-width", document.defaultView.getComputedStyle(e)["border-width"]));
                var e
            },
            f = function() {
                return r.getBoundingClientRect().width
            },
            v = null !== (n = function() {
                var t = e.dataset.steps;
                if (!t) return null;
                try {
                    if (t = JSON.parse(t), !Array.isArray(t)) return null;
                    if (t.length < 2) return null;
                    t = t.filter((function(e) {
                        return "number" == typeof e || "string" == typeof e
                    }))
                } catch (e) {
                    return null
                }
                return t
            }()) && void 0 !== n ? n : function() {
                var t = parseInt(e.dataset.min) || 0,
                    n = parseInt(e.dataset.max);
                n = isNaN(n) ? 100 : n;
                var i = parseInt(e.dataset.step) || 1;
                i = i < 0 ? 1 : i;
                for (var r = [], d = t; d <= n;) r.push(d), d += i;
                return r
            }(),
            m = function() {
                return (e.getBoundingClientRect().width - c()) / (v.length - 1)
            },
            h = !1,
            g = (u = parseInt(e.dataset.startIndex) || 0) >= v.length ? v.length - 1 : u < 0 ? 0 : u,
            E = null;

        function _(t) {
            g = (g = Math.round((t - e.getBoundingClientRect().left - c() / 2) / m())) >= v.length ? v.length - 1 : g < 0 ? 0 : g
        }

        function p() {
            var t = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
            if (t || E !== g) {
                var n = m() * g;
                i.style.left = n + "px", d.style.visibility = 0 === g ? "hidden" : "visible", l.style.visibility = g === v.length - 1 ? "hidden" : "visible", r.innerHTML = "" + y(v[g]), r.style.left = -(o() + f() / 2 - c() / 2) + "px", s.style.width = n + c() / 2 + "px", E !== g && (a.detail.value = v[g], e.dispatchEvent(a)), E = g
            }
        }

        function y(e) {
            var t = +e;
            return Number.isNaN(t) ? e : e.toLocaleString("en-US")
        }
        e.insertAdjacentElement("afterbegin", d), d.classList.add("r-slider__cloud", "r-slider__cloud_left"), e.insertAdjacentElement("beforeend", l), l.classList.add("r-slider__cloud", "r-slider__cloud_right"), e.insertAdjacentElement("beforeend", s), s.classList.add("r-slider__filled-line"), r.style.visibility = "visible", d.innerHTML = "" + y(v[0]), l.innerHTML = "" + y(v[v.length - 1]), i.addEventListener("mousedown", (function() {
            h = !0
        })), document.addEventListener("mouseup", (function() {
            h = !1
        })), document.addEventListener("mousemove", (function(e) {
            h && (_(e.clientX), p())
        })), i.addEventListener("touchstart", (function() {
            h = !0
        })), document.addEventListener("touchend", (function() {
            h = !1
        })), document.addEventListener("touchmove", (function(e) {
            h && (_(e.touches[0].clientX), p())
        })), window.addEventListener("resize", (function() {
            p(!0)
        })), e.addEventListener("click", (function(e) {
            _(e.clientX), p()
        })), p()
    }))
}

export default RangeSlider;