import Swiper, { Navigation, Pagination, Thumbs, EffectFade, Controller, Autoplay, Scrollbar } from "swiper";
import $, { event } from 'jquery';
import Tabs from "../../../plugins/Tabs";
import { WOW } from 'wowjs';
import RangeSlider from "../../../plugins/range-slider";
import Toggle from "../../../plugins/Toggle";

const HomePage = () => {

  RangeSlider();

  // let wow = new WOW(
  //   {
  //     boxClass:     'wow',      // animated element css class (default is wow)
  //     animateClass: 'animated', // animation css class (default is animated)
  //     offset:       100,          // distance to the element when triggering the animation (default is 0)
  //     mobile:       true,       // trigger animations on mobile devices (default is true)
  //     live:         true,       // act on asynchronously loaded content (default is true)
  //     callback:     function(box) {
  //       // the callback is fired every time an animation is started
  //       // the argument that is passed in is the DOM node being animated
  //     },
  //     scrollContainer: null // optional scroll container selector, otherwise use window
  //   }
  // );
  // wow.init();

    function throttle(callee, timeout) {
      let timer = null

      return function perform(...args) {
        if (timer) return
        timer = setTimeout(() => {
          callee(...args)
          
          clearTimeout(timer)
          timer = null
        }, timeout)
      }
    }

    var windowWidth = $(window).width();

    $('.customers').mousemove(function(event){
      var moveX = (($(window).width() / 2) - event.pageX) * 1.4;
      $('.customers__row--primary').css('margin-left', moveX + 'px');
      $('.customers__row--secondary').css('margin-left', -moveX + 'px');
    });

    // scrolling for top


    Swiper.use([Pagination, Thumbs, EffectFade, Navigation, Controller, Autoplay, Scrollbar]);


    (function() {
      'use strict';

      // breakpoint where swiper will be destroyed
      // and switches to a dual-column layout
      const breakpoint = window.matchMedia( '(min-width: 992px)' );

      // keep track of swiper instances to destroy later
      let mySwiper;

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      const breakpointChecker = function() {

        // if larger viewport and multi-row layout needed
        if ( breakpoint.matches === true ) {

          // clean up old instances and inline styles when available
          if ( mySwiper !== undefined ) mySwiper.destroy( true, true );

          // or/and do nothing
          return;

          // else if a small viewport and single column layout needed
          } else if ( breakpoint.matches === false ) {

            // fire small viewport version of swiper
            return enableSwiper();

          }

      };

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      const enableSwiper = function() {

        mySwiper = new Swiper('.productsCarousel', {
        spaceBetween: 30,
        slidesPerView: 'auto',
        simulateTouch: true,
        watchOverflow: true,
        watchSlidesVisibility: true,
        cssMode: false,
        loop: false,
        scrollbar: {
          el: '.carousel-scrollbar',
          dragClass: 'carousel-scrollbar-drag',
          draggable: true,
        },
        navigation: {
            nextEl: '',
            prevEl: '',
        },
            pagination: {
            el: '',
            clickable: true,
        },
            mousewheel: {
            forceToAxis: true,
        },
            touchReleaseOnEdges: true,
            keyboard: false,
        });

      };

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      // keep an eye on viewport size changes
      breakpoint.addListener(breakpointChecker);

      // kickstart
      breakpointChecker();



    })(); /* IIFE end */

    var introCarousel = new Swiper("#introCarousel", {
        speed: 1000,
        slidesPerView: 1,
        direction: 'vertical',
        spaceBetween: 10,
        freeMode: true,
        watchSlidesProgress: true,
        allowTouchMove: false,
        touchReleaseOnEdges: true,
        mousewheel: false,
        simulateTouch: false,
        observer: true,
        observeParents: true,
        slideToClickedSlide: false,
        pagination: {
          el: '.dots',
          clickable: true,
          bulletClass: 'dots-bullet',
          bulletActiveClass: 'active',
        },
    });

    var servicesCarousel = new Swiper(".servicesCarousel", {
      spaceBetween: 30,
      centeredSlides: false,
      allowTouchMove: true,
      loop: false,
      simulateTouch: true,
      navigation: {
        nextEl: '.circle-btn--next',
        prevEl: '.circle-btn--prev',
      },
      watchSlidesProgress: true,
      slideToClickedSlide: false,
      on: {

      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });


    var advantagesCarousel = new Swiper('#advantagesCarousel', {
      spaceBetween: 30,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '#gameFeaturesCarouselNext',
          prevEl: '#gameFeaturesCarouselPrev',
        },
        pagination: {
          el: '#gameFeaturesCarouselPrev',
          clickable: true
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1400: {
            slidesPerView: 4,
          },
        },
    });

    var casesCarousel = new Swiper('#casesCarousel', {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: false,
        allowTouchMove: false,
        loop: false,
        simulateTouch: false,
        navigation: {
          nextEl: '#casesCarouselNext',
          prevEl: '#casesCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 1,
          },
        },
    });

    const setZero = (num) => {
      return num && num < 9 ? `0${num}` : num;
    }

    function updSwiperNumericPagination() {
      
      this.el.querySelector(".counter-nav-pagination__count").innerHTML = '<span class="count">' + (setZero(this.realIndex + 1)) + '</span>/<span class="total">' + setZero(this.el.swiper.slides.length) + "</span>";
    }

    var casesInnerCarousel = new Swiper('.casesInnerCarousel', {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '.counter-nav-pagination__btn--next',
          prevEl: '.counter-nav-pagination__btn--prev',
        },
        pagination: {
          el: "",
          clickable: true,
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          init: updSwiperNumericPagination,
          slideChange: updSwiperNumericPagination
        },
        breakpoints: {
          
        },
    });


    var portfolioCarousel = new Swiper('#portfolioCarousel', {
        spaceBetween: 19,
        centeredSlides: false,
        allowTouchMove: true,
        loop: true,
        simulateTouch: true,
        speed: 1000,
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '#portfolioCarouselNext',
          prevEl: '#portfolioCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 6,
          },
        },
    });

    // document.getElementById('portfolioCarousel').addEventListener('mouseenter', () => {
    //   portfolioCarousel.autoplay.stop();
    // });

    // document.getElementById('portfolioCarousel').addEventListener('mouseleave', () => {
    //   portfolioCarousel.autoplay.start();
    // });


    var catalogsCarousel = new Swiper('#catalogsCarousel', {
        spaceBetween: 19,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '#teamCarouselNext',
          prevEl: '#teamCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        scrollbar: {
          el: '.carousel-scrollbar',
          dragClass: 'carousel-scrollbar-drag',
          draggable: true,
        },
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 4,
          },
          1300: {
            slidesPerView: 5,
          },
          1400: {
            slidesPerView: 6,
          },
        },
    });


    var calcProductsCarousel = new Swiper('#calcProductsCarousel', {
        spaceBetween: 19,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '#teamCarouselNext',
          prevEl: '#teamCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        scrollbar: {
          el: '.carousel-scrollbar',
          dragClass: 'carousel-scrollbar-drag',
          draggable: true,
        },
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 2,
          },
          1300: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 3,
          },
        },
    });


    var planCarousel = new Swiper('#planCarousel', {
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 10,
        freeMode: true,
        watchSlidesProgress: true,
        allowTouchMove: false,
        navigation: {
          nextEl: '#planCarouselNext',
          prevEl: '#planCarouselPrev',
        },
        pagination: {
          el: '.dots-2',
          clickable: true,
          bulletClass: 'dots-bullet',
          bulletActiveClass: 'active',
        },
        breakpoints: {
          0: {
            direction: 'horizontal',
          },
          992: {
            direction: 'vertical',
          },
        },
    });


    var stagesCarousel = new Swiper('#stagesCarousel', {
        spaceBetween: 20,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '#teamCarouselNext',
          prevEl: '#teamCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        scrollbar: {
          el: '.carousel-scrollbar',
          dragClass: 'carousel-scrollbar-drag',
          draggable: true,
        },
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 4,
          },
          1300: {
            slidesPerView: 5,
          },
          1400: {
            slidesPerView: 6,
          },
        },
    });

    var photosCarousel = new Swiper('.photosCarousel', {
        spaceBetween: 20,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '',
          prevEl: '',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        scrollbar: {
          el: '.carousel-scrollbar',
          dragClass: 'carousel-scrollbar-drag',
          draggable: true,
        },
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 4,
          },
          1300: {
            slidesPerView: 5,
          },
          1400: {
            slidesPerView: 6,
          },
        },
    });


    var articlesCarousel = new Swiper('#articlesCarousel', {
        spaceBetween: 30,
        centeredSlides: false,
        allowTouchMove: true,
        loop: false,
        simulateTouch: true,
        navigation: {
          nextEl: '#articlesCarouselNext',
          prevEl: '#articlesCarouselPrev',
        },
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        on: {
          click(event) {
            console.log('event', event);
            
          }
        },
        breakpoints: {
          0: {
            slidesPerView: 'auto',
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          },
        },
    });

    const playVideo = (event) => {

      event.slides.forEach((item) => {
        const video = item.querySelector('video');
        video && video.pause();
      });
      
      const activeElement = event.slides[event.activeIndex];
      const video = activeElement && activeElement.querySelector('video');
      
      video && video.play();
    }


  const servicesTabs = new Tabs({
    tabSelectorById: '#productsTabs',
  });
  
}

export default HomePage;