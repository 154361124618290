import { overflowHidden, overflowVisible } from "../utils/overflow";
import toggleHTMLClass from "../utils/toggleHTMLClass";

function Toggle(options) {
    console.log(options);
    

    const {
        toggleButton,
        toggleContainer,
        outsideClose,
        bodyOverflowHidden
    } = options || {};

    

    this.toggleButton = options.toggleButton;
    this.toggleButtonElement = document.getElementById(this.toggleButton);
    this.toggleContainer = toggleContainer;
    this.toggleContainerElement = document.getElementById(this.toggleContainer);
    this.isShow = false;
    this.outsideClose = outsideClose || false;
    this.bodyOverflowHidden = bodyOverflowHidden || false;

    console.log('this.bodyOverflowHidden', this.bodyOverflowHidden);
    

    this.show = () => {
        this.isShow = true;
        this.toggleButtonElement && this.toggleButtonElement.classList.add('active');
        this.toggleContainerElement && this.toggleContainerElement.classList.add('show');

        this.bodyOverflowHidden && (
            overflowHidden()
        );
    }

    this.hidden = () => {
        this.isShow = false;
        this.toggleButtonElement &&  this.toggleButtonElement.classList.remove('active');
        this.toggleContainerElement && this.toggleContainerElement.classList.remove('show');

        this.bodyOverflowHidden && (
            overflowVisible()
        );
    };

    
    this.outsideCloseHandler = (event) => {
        let isClickInside = (
            this.toggleContainerElement && this.toggleContainerElement.contains(event.target) ||
            (
                this.toggleButtonElement.contains(event.target)
            )
        );
        

        if (!isClickInside) {
            this.hidden();
            document.removeEventListener('click', this.outsideCloseHandler);
        }
    }

    this.init = () => {
        this.toggleButtonElement && this.toggleButtonElement.addEventListener('click', (event) => {
            this.isShow ? (
                this.hidden()
            ) : (
                this.show()
            );

            if (this.outsideClose) {
                document.addEventListener('click', this.outsideCloseHandler);
            }
        });
    }

    this.init();
}

export default Toggle;