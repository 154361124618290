import Swiper, { Navigation, Pagination, Thumbs, EffectFade, Controller, Autoplay, Scrollbar } from "swiper";
import Tabs from "../../../plugins/Tabs";

const CatalogPage = () => {

  const filterSidebar = document.getElementById('filterSidebar');
  const catergorySidebar = document.getElementById('catergorySidebar');
  const filterSidebarContainer = document.getElementById('filterSidebarContainer');
  const categorySidebarContainer = document.getElementById('categorySidebarContainer');

  const buttons = [filterSidebar, catergorySidebar];
  const containers = [filterSidebarContainer, categorySidebarContainer];

  buttons.forEach((button, i) => {
    button && button.addEventListener('click', () => {
      if (containers[i]) {
        if (containers[i].classList.contains('active')) {
          containers[i].classList.remove('active');
          containers[i].slideUp();
        } else {
          containers[i].classList.add('active');
          containers[i].slideDown();
        }
      }
    });
  });

    Swiper.use([Pagination, Thumbs, EffectFade, Navigation, Controller, Autoplay, Scrollbar]);
  
    var subcategoriesCarousel = new Swiper('#subcategoriesCarousel', {
      spaceBetween: 19,
      slidesPerView: 'auto',
      centeredSlides: false,
      allowTouchMove: true,
      loop: false,
      simulateTouch: true,
      navigation: {
        nextEl: '#teamCarouselNext',
        prevEl: '#teamCarouselPrev',
      },
      watchSlidesProgress: true,
      slideToClickedSlide: false,
      scrollbar: {
        el: '.carousel-scrollbar',
        dragClass: 'carousel-scrollbar-drag',
        draggable: true,
      },
      on: {
        click(event) {
          console.log('event', event);
          
        }
      },
      breakpoints: {
      },
  });

  
  var newsCarousel = new Swiper('#newsCarousel', {
      spaceBetween: 19,
      centeredSlides: false,
      allowTouchMove: true,
      loop: false,
      simulateTouch: true,
      navigation: {
        nextEl: '',
        prevEl: '',
      },
      watchSlidesProgress: true,
      slideToClickedSlide: false,
      scrollbar: {
        el: '.carousel-scrollbar',
        dragClass: 'carousel-scrollbar-drag',
        draggable: true,
      },
      on: {
        click(event) {
          console.log('event', event);
          
        }
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 2,
        },
        1300: {
          slidesPerView: 3,
        },
        1400: {
          slidesPerView: 3,
        },
      },
  });
  


  const productTabs = new Tabs({
    tabSelectorById: '#productTabs',
  });
}

export default CatalogPage;