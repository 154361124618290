import Nav from './components/Nav';
import HomePage from './containers/Main/HomePage';
import routesConfig from './routes/routesConfig';
import './jquery-3.7.1.min';
import './plugins/slideToggle.min';

document.addEventListener('DOMContentLoaded', () => {

    var links = document.getElementsByClassName('scroll-link');

    for (var i = 0; i < links.length; i++) {
        links[i].onclick = scroll;
    }

    function scroll(e) {
        e.preventDefault();
        var id = this.getAttribute('href').replace('#', '');
        var target = document.getElementById(id).getBoundingClientRect().top;
        animateScroll(target);
    }

    function animateScroll(targetHeight) {
        targetHeight = document.body.scrollHeight - window.innerHeight > targetHeight + scrollY ? 
            targetHeight : document.body.scrollHeight - window.innerHeight;
        var initialPosition = window.scrollY;
        var SCROLL_DURATION = 30;
        var step_x = Math.PI / SCROLL_DURATION;
        var step_count = 0;
        requestAnimationFrame(step);
        function step() {
            if (step_count < SCROLL_DURATION) {
                requestAnimationFrame(step);
                step_count++;
                window.scrollTo(0, initialPosition + targetHeight * 0.25 * Math.pow((1 - Math.cos(step_x * step_count)), 2));
            }
        }
    }

    routesConfig && routesConfig.length && routesConfig.forEach((routeConfig) => {
        const {
            pages,
            callbacks
        } = routeConfig;

        try {
            const locationSplitArray = window.location.pathname.split('/');
            const last = locationSplitArray[locationSplitArray.length - 1];
            const pagePathname = last && last.replace('.html', '');
            
            pages && pages.length && pages.forEach(( page ) => {
                callbacks && callbacks.length && callbacks.forEach((callback) => {
                    page === `/${pagePathname}` && ( callback() ),
                    page === '*' && ( callback() )
                });
            });
        } catch(error) {
            console.error(error);
        }
    });
    
});
