import toggleHTMLClass from "../utils/toggleHTMLClass";

function Tabs(options) {
    const {
        tabSelectorById,
        target = false,
        mob
    } = options || {};

    console.log(tabSelectorById);
    
    
    

    this.tabSelectorById = tabSelectorById && tabSelectorById.slice(1);
    this.target = target;
    this.fadeAttributeText = mob ? 'data-fade-in-mob' : 'data-fade-in';

    console.log('this.target', this.target, this.tabSelectorById);
    
    
    this.tabElement = document.getElementById(`${this.tabSelectorById}`);
    this.tabButtonItems = this.tabElement && this.tabElement.querySelectorAll(`[data-js-tab-button="${tabSelectorById}"]`);
    this.tabContentItems = this.tabElement && this.tabElement.querySelectorAll(`[data-js-tab-content-item="${tabSelectorById}"]`);
    this.tabTargetButtonItems = [];
    this.tabTargetContentItems = [];

    if (this.target) {
        this.tabTargetButtonItems = this.tabElement.querySelectorAll('[data-js-tab-target-button]');
        this.tabTargetContentItems = this.tabElement.querySelectorAll('[data-js-tab-target-content-item]');
        
    }


    this.hideTabContent = () => {
        [...this.tabContentItems, ...this.tabTargetContentItems].forEach((item) => {
            item.classList.add('d-none');
            item.setAttribute(this.fadeAttributeText, '');
            item.classList.remove('d-block');
            item.classList.remove('show-tab');
        });

        [...this.tabButtonItems, ...this.tabTargetButtonItems].forEach((tabButtonItem) => {
            toggleHTMLClass({
                element: tabButtonItem,
                type: 'remove'
            });

            tabButtonItem.classList.remove('active');
        });
    }

    this.showTabContent = (i) => {
        const tabButtonElement = this.tabButtonItems[i];
        const tabContentItemElement = this.tabContentItems[i];
        
        tabButtonElement && (
            toggleHTMLClass({
                element: tabButtonElement,
                type: 'add'
            })
        );

        tabButtonElement && tabButtonElement.classList.add('active');
        
        tabContentItemElement && tabContentItemElement.classList.add('d-block');
        tabContentItemElement && tabContentItemElement.classList.remove('d-none');
    }

    this.showTargetTabContent = ({
        tabButtonElement,
        container
    }) => {
        const containerElement = this.tabElement.querySelector(`[data-js-tab-target-content-item="${container}"]`);
        

        tabButtonElement && tabButtonElement.classList.add('active');
        containerElement && containerElement.classList.remove('d-none');
        containerElement && containerElement.classList.add('show-tab');

    }
    

    if (this.tabElement) {
        this.hideTabContent();

        if (this.target) {
            this.tabTargetContentItems[0].classList.remove('d-none');
            this.tabTargetContentItems[0].classList.add('show-tab');

            this.tabTargetButtonItems.forEach((btn) => {
                btn && btn.addEventListener('click', (event) => {
                    const currentTarget = event.currentTarget;
                    const id = currentTarget.dataset.jsTabTargetButton;
                    this.hideTabContent();
                    this.showTargetTabContent({
                        tabButtonElement: currentTarget,
                        container: id
                    });
                });
            });
        } else {
            this.showTabContent(0);

            this.tabButtonItems.forEach((tabButtonItem, index) => {
                tabButtonItem.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.hideTabContent();
                    this.showTabContent(index);
                });
            });
        }
    }
}

export default Tabs;