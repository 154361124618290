import HomePage from "../containers/Main/HomePage";
import Nav from "../components/Nav";
import toggle from "../utils/toggle";
import Modal from "../plugins/Modal";
import CatalogPage from "../containers/Main/CatalogPage/CatalogPage";
import ProductPage from "../containers/Main/ProductPage";
import ScrollTop from "../components/ScrollTop/ScrollTop";


const routesConfig = [
    {
        pages: ['/', '/index'],
        callbacks: [
            HomePage,
        ],
    },
    {
        pages: ['/catalog'],
        callbacks: [
            CatalogPage,
        ],
    },
    {
        pages: ['/product'],
        callbacks: [
            ProductPage,
        ],
    },
    {
        pages: ['*'],
        callbacks: [
            Nav,
            toggle,
            Modal,
            ScrollTop
        ]
    }
];

export default routesConfig;