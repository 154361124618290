import Swiper, { Navigation, Pagination, Thumbs, EffectFade, Controller, Autoplay, Scrollbar } from "swiper";
import Tabs from "../../../plugins/Tabs";
import SelectBox from "../../../plugins/SelectBox";

const ProductPage = () => {

    Swiper.use([Pagination, Thumbs, EffectFade, Navigation, Controller, Autoplay, Scrollbar]);


  var productsCarousel = new Swiper('#productsCarousel', {
      spaceBetween: 20,
      centeredSlides: false,
      allowTouchMove: true,
      loop: false,
      simulateTouch: false,
      navigation: {
        nextEl: '#productsCarouselNext',
        prevEl: '#productsCarouselPrev',
      },
      watchSlidesProgress: true,
      slideToClickedSlide: false,
      on: {
        click(event) {
          console.log('event', event);
          
        }
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
          scrollbar: {
            el: '.carousel-scrollbar',
            dragClass: 'carousel-scrollbar-drag',
            draggable: true,
          },
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
        1400: {
          slidesPerView: 4,
        },
      },
  });

  var productMainImageThumbsCarousel = new Swiper("#productMainImageThumbsCarousel", {
      spaceBetween: 5,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
      navigation: {
          nextEl: '#productMainImageCarouselNext',
          prevEl: '#productMainImageCarouselPrev'
      },
      scrollbar: {
        el: '.carousel-scrollbar',
        dragClass: 'carousel-scrollbar-drag',
        draggable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
          spaceBetween: 7,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 6,
        },
      },
    });

    var productMainImageCarousel = new Swiper("#productMainImageCarousel", {
      effect: 'fade',
      spaceBetween: 0,
      navigation: {
          nextEl: '#productMainImageCarouselNext',
          prevEl: '#productMainImageCarouselPrev'
      },
      pagination: {
          el: '#productMainImageCarouselPagination',
          clickable: true,
          bulletClass: 'dot',
          bulletActiveClass: 'active'
      },
      thumbs: {
        swiper: productMainImageThumbsCarousel,
      },
  });

  

  productMainImageThumbsCarousel.params.control = productMainImageCarousel;
  productMainImageCarousel.params.control = productMainImageThumbsCarousel;
  


  const productTabs = new Tabs({
    tabSelectorById: '#productTabs',
  });

  const signSizeSelectElement = document.getElementById('signSizeSelect');

  const signSizeSelect = new SelectBox(signSizeSelectElement);
}

export default ProductPage;