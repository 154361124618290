import toggleHTMLClass from "./toggleHTMLClass";
import $ from "jquery";

const toggle = () => {
    const togglesItemContainers = document.querySelectorAll('[data-js-toggle]');

    togglesItemContainers.forEach((togglesItemContainer) => {
        
        const toggleButtons = togglesItemContainer.querySelectorAll('[data-js-toggle-button]');

        toggleButtons.forEach((toggleButton) => {
            toggleButton.addEventListener('click', (event) => {
                const _this = event.currentTarget;

                const parent = _this.closest('[data-js-toggle-button-parent]');
                const block = parent && parent.querySelector('[data-js-toggle-block]');
                const toggleClassElements = parent && parent.querySelectorAll('[data-js-toggle-class]');

                if (!block.classList.contains('active')) {
                    toggleClassElements.forEach((toggleClassElement) => {
                        toggleHTMLClass({
                            element: toggleClassElement,
                            type: 'add'
                        });
                    });

                    
                    toggleClassElements.forEach((toggleClassElement) => {
                        toggleHTMLClass({
                            element: toggleClassElement,
                            type: 'remove'
                        });
                    });

                    // block.slideDown();
                    
                    $( block ).slideDown()
                    _this.classList.add('active');
                    block.classList.add('active');
                    parent.classList.add('active');
                    
                } else {

                    // block.slideUp();
                    $( block ).slideUp()
                    _this.classList.remove('active');
                    block.classList.remove('active');
                    parent.classList.remove('active');
                }
            });
        });
    });
}

export default toggle;